import { defineComponent as _defineComponent } from 'vue'
import DashBoardModule from "@ems/containers/Dashboard/Dashboard.module";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import { ElMessage } from "element-plus/lib/components/message";
import { Field, Form } from "vee-validate";
import { ref, onMounted, onUnmounted, watch } from "vue";
import { number, object, string } from "yup";


export default /*@__PURE__*/_defineComponent({
  __name: 'CarbonEmissionTargets.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const radioManually = ref("");
const inputManually = ref(0);

const handleChangeYear = async (value: Date, actions: any) => {
  const yearSelected = new Date(value).getFullYear();
  actions();
  await ScopeTwoEmissionModule.fetchS2Emission({ Year: `${yearSelected}` });
  ScopeTwoEmissionModule.currentYearScopeTwoEmission(yearSelected.toString());
  renewTargetType();
};

const handleChangeTarget = (value: number | Event) => {
  if (value === 30 || value === 50 || value === 100) {
    radioManually.value = "";
    inputManually.value = 0;
  }
};

const schema = object({
  Year: string().required("Year is a required field").nullable(),
  Target: number().required("Target is a required field").nullable(),
});

const onSubmit = async (values: any, actions: any) => {
  if (
    isNaN(values.Target) ||
    isNaN(inputManually.value) ||
    inputManually.value > 100 ||
    inputManually.value < 0
  ) {
    ElMessage.error("The target value range from 0 to 100");
    actions.resetForm();
    renewTargetType();
    return false;
  }
  if (radioManually.value === "1") {
    values.Target = inputManually.value;
  }
  values.Year = new Date(values.Year).getFullYear();
  await DashBoardModule.createNewTarget(values);
  await ScopeTwoEmissionModule.fetchS2Emission({
    Year: values.Year.toString(),
  });
  renewTargetType(actions);
  ScopeTwoEmissionModule.fetchAllDataChartEmission({
    Year: values.Year.toString(),
    FacilityId: "",
  });
};

const renewTargetType = (actions) => {
  if (
    ScopeTwoEmissionModule.dataScopeTwoEmissionGetter.Target === 30 ||
    ScopeTwoEmissionModule.dataScopeTwoEmissionGetter.Target === 50 ||
    ScopeTwoEmissionModule.dataScopeTwoEmissionGetter.Target === 100
  ) {
    radioManually.value = "";
    inputManually.value = 0;
  } else {
    radioManually.value = "1";
    inputManually.value =
      ScopeTwoEmissionModule.dataScopeTwoEmissionGetter.Target;
  }
  actions && actions.resetForm();
};

onMounted(async () => {
  await ScopeTwoEmissionModule.fetchS2Emission({
    Year: `${DashBoardModule.currentYearGetter}`,
  });
  ScopeTwoEmissionModule.currentYearScopeTwoEmission(
    DashBoardModule.currentYearGetter
  );
  renewTargetType();
});
onUnmounted(() => {
  ScopeTwoEmissionModule.currentYearScopeTwoEmission(new Date());
});

const __returned__ = { radioManually, inputManually, handleChangeYear, handleChangeTarget, schema, onSubmit, renewTargetType, get DashBoardModule() { return DashBoardModule }, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, get ElMessage() { return ElMessage }, get Field() { return Field }, get Form() { return Form }, ref, onMounted, onUnmounted, watch, get number() { return number }, get object() { return object }, get string() { return string } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})