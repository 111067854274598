import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 2xl:gap-5 gap-3 mb-5 relative" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = { class: "2xl:col-span-2 mt-2 lg:mt-0 lg:ml-5 2xl:ml-10" }
const _hoisted_7 = { class: "space-y-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/scope-2-renewables-performance" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Seting Information")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "scope-2-emission-targets" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Scope 2 Emission Targets ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["CarbonEmissionTargetsForm"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl font-bold" }, " Scope 2 Renewables Performance ", -1)),
          (!$setup.isLoadingScopeTwoEnergyLoad)
            ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                key: 0,
                class: _normalizeClass(_ctx.styles['column-size']),
                data: $setup.dataScopeTwoEnergyLoad,
                colors: ['#ffeecc', '#ffeecc', '#5e5e5e'],
                data_2: $setup.dataScopeTwoRenewableEnergy,
                colors_2: ['#addfa4', '#addfa4', '#5e5e5e'],
                seriesName: "Energy Load (kWh)",
                seriesName_2: "Renewables (kWh)",
                lineSeriesName: "Renewables (%)",
                lineData: $setup.dataScopeTwoLineMarker,
                lineMarker: $setup.dataScopeTwoEmissionTarget,
                percentChart: $setup.dataMaxPercentChart,
                lineMarkerName: "Target (%)",
                yTitle: "kWh",
                legendStyle: {
              position: 'bottom',
              fontSize: 14,
              padding: [10, 100, 100, 100],
            },
                legendLayout: "horizontal",
                withExtraYScale: true,
                lineAllowExtraYScale: true
              }, null, 8, ["class", "data", "data_2", "lineData", "lineMarker", "percentChart"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}