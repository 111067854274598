import { defineComponent as _defineComponent } from 'vue'
import CarbonEmissionTargetsForm from "@ems/containers/GreenOptions/TheForm/CarbonEmissionTargets.Form.vue";
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";
import { computed, onMounted, watch } from "vue";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import DashBoardModule from "@ems/containers/Dashboard/Dashboard.module";


export default /*@__PURE__*/_defineComponent({
  __name: 'CET',
  setup(__props, { expose: __expose }) {
  __expose();

const dataCurrentYearScopeTwoEmission = computed<string | number | Date>(
  () => ScopeTwoEmissionModule.dataCurrentYearScopeTwoEmissionGetter
);
const dataScopeTwoEnergyLoad = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoEnergyLoadGetter
);
const isLoadingScopeTwoEnergyLoad = computed(
  () => ScopeTwoEmissionModule.loadingScopeTwoEnergyLoadGetter
);
const dataScopeTwoEmissionTarget = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoEmissionTargetGetter
);
const dataScopeTwoRenewableEnergy = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoRenewableEnergyGetter
);
const dataScopeTwoLineMarker = computed(
  () => ScopeTwoEmissionModule.dataScopeTwoLineMarkerGetter
);
const dataMaxPercentChart = computed(
  () => ScopeTwoEmissionModule.dataMaxPercentChartGetter
);
watch(
  () => dataCurrentYearScopeTwoEmission.value,
  () => {
    ScopeTwoEmissionModule.fetchAllDataChartEmission({
      Year: dataCurrentYearScopeTwoEmission.value.toString(),
      FacilityId: "",
    });
  }
);
onMounted(() => {
  ScopeTwoEmissionModule.fetchAllDataChartEmission({
    Year: DashBoardModule.currentYearGetter,
    FacilityId: "",
  });
});

const __returned__ = { dataCurrentYearScopeTwoEmission, dataScopeTwoEnergyLoad, isLoadingScopeTwoEnergyLoad, dataScopeTwoEmissionTarget, dataScopeTwoRenewableEnergy, dataScopeTwoLineMarker, dataMaxPercentChart, CarbonEmissionTargetsForm, ColumnChart, computed, onMounted, watch, get ScopeTwoEmissionModule() { return ScopeTwoEmissionModule }, get DashBoardModule() { return DashBoardModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})