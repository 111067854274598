import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box relative" }
const _hoisted_2 = {
  class: "flex items-center -mt-7",
  style: { marginLeft: '120px' }
}
const _hoisted_3 = { class: "-ml-4" }
const _hoisted_4 = { class: "flex justify-end" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Form"], {
      as: "el-form",
      onSubmit: $setup.onSubmit,
      "validation-schema": $setup.schema,
      class: "my-4",
      "label-width": "120px",
      "initial-values": {
        Year: `'${$setup.ScopeTwoEmissionModule.dataScopeTwoEmissionGetter.Year}'`,
        Target: $setup.ScopeTwoEmissionModule.dataScopeTwoEmissionGetter.Target,
      }
    }, {
      default: _withCtx(({ setFieldValue, isSubmitting, resetForm }) => [
        _createElementVNode("div", {
          class: _normalizeClass(['my-3', isSubmitting && 'isSubmitting-rounded'])
        }, [
          _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "text-left text-xl font-bold" }, "Scope 2 Emission Targets", -1)),
          _createVNode($setup["Field"], { name: "Year" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline inline",
                error: errorMessage,
                label: "Year"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, _mergeProps(field, {
                    "model-value": value,
                    type: "year",
                    editable: false,
                    "clear-icon": "",
                    clearable: false,
                    placeholder: "",
                    onChange: (value) => $setup.handleChangeYear(value, resetForm)
                  }), null, 16, ["model-value", "onChange"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 2
          }, 1024),
          _createVNode($setup["Field"], { name: "Target" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "pb-1",
                label: "RE Target",
                error: errorMessage
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio_group, _mergeProps({ class: "flex flex-col" }, field, {
                    "model-value": value,
                    onChange: $setup.handleChangeTarget
                  }), {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio, {
                        label: Number(30)
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("30%")
                        ])),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_radio, {
                        label: Number(50)
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("50%")
                        ])),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_radio, {
                        label: Number(100)
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("100%")
                        ])),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_radio, {
              modelValue: $setup.radioManually,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.radioManually) = $event)),
              label: "1",
              onChange: 
              () => {
                setFieldValue('Target', 0);
              }
            
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Others: Please specify ")
              ])),
              _: 2
            }, 1032, ["modelValue", "onChange"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_input_number, {
                class: "w-20 inputfield inputfield__underline",
                placeholder: "",
                controls: false,
                modelValue: $setup.inputManually,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.inputManually) = $event)),
                onFocus: 
                () => {
                  setFieldValue('Target', 0);
                  $setup.radioManually = '1';
                }
              
              }, null, 8, ["modelValue", "onFocus"]),
              _cache[6] || (_cache[6] = _createTextVNode(" % "))
            ])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn--green-primary",
              disabled: isSubmitting
            }, " Save ", 8, _hoisted_5)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema", "initial-values"])
  ]))
}